import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { connect } from "react-redux";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import Selectors from "../../Selectors";
import ProductListItem from "../../Components/ProductListItem";
import FilterPanel from "../../Components/FilterPanel";
import labels from "../../Domain/CategoryTree";

const LayoutWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #e6e6e6;
  flex-direction: row-reverse;

  & > .tours-filter {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
  }

  & > .tour-detail-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
`;

const Wrapper = styled.div`
  & > section.tour {
    padding: 20px;
    text-align: center;
    background-color: darkgrey;
    color: white;

    & > h2 {
      color: white;
    }
  }

  & > section.products {
  }
`;

class TourDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tour: null,
      items: null
    };
  }

  componentDidMount() {
    let { appActions, id } = this.props;
    appActions
      .fetchTourById(id)
      .then(tour => this.setState({ tour }))
      .then(() => appActions.fetchProducts({ limit: 600, offset: 0, tour: id }))
      .then(resp => {
        console.log(resp.results);
        this.setState({ items: resp.results });
      })
      .catch(err => {
        // TODO: handle for 404 and api failure
        console.warn(err);
      });
  }

  render() {
    let { navActions } = this.props;
    let { tour, items } = this.state;

    return (
      <LayoutWrapper>
        <div className="tours-filter">
          <FilterPanel
            labels={labels}
            activeFilter={""}
            onFilterItemClick={updateLabel =>
              navActions.push(`/tours/${updateLabel}`)
            }
          />
        </div>

        <Wrapper className="tour-detail-content">
          {tour && (
            <section className="tour">
              <h2>Tour Info</h2>
              <h3>id: {tour.id}</h3>
              <h3>name: {tour.name}</h3>
            </section>
          )}

          <section className="products">
            {items &&
              items.map(item => (
                <ProductListItem
                  key={item.id}
                  item={item}
                  onClick={() => navActions.push(`/product/?id=${item.id}`)}
                />
              ))}
          </section>
        </Wrapper>
      </LayoutWrapper>
    );
  }
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      id: Selectors.getQueryParams(ownProps).id
    }),
    ActionCreator
  )(TourDetailPage)
);
