import React, { Component } from "react";
import styled from "styled-components";
import * as Widget from "../Components/Widget2";
import { getDisplay } from "../Utils/TreeUtil";
import jsonParser from "../Utils/JsonParser";
import labels from "../Domain/CategoryTree";

class ProductListItem extends Component {
  render() {
    let { css, item, onClick } = this.props;
    // comment means item data will have this field.
    // also have fake data to ui test.

    return (
      <Wrapper css={css} onClick={onClick}>
        <ImageContainer
          img={item.image || "/images/sample/product-sample.jpg"}
          extraCss="margin-right: 25px;"
        >
          <div className="image" />
          <div className="badge">NEW IN</div>
        </ImageContainer>
        <DetailContainer>
          <div>
            <h1>{item.name || "---"}</h1>
            <p>
              {item.description.length > 235
                ? item.description.slice(0, 235) + "..."
                : item.description}
            </p>
          </div>

          <div>
            <Widget.Row justify="flex-end" align="center">
              <div className="original-price" style={{ marginRight: 5 }}>
                $560
                {/* {item.original_price && `$${item.original_price}`} */}
              </div>
              <div className="price">{item.price}</div>
            </Widget.Row>
            <Widget.Row justify="space-between" wrap="true">
              <Widget.Row wrap="true">
                {jsonParser(item.label, []).map((l, i) => {
                  let name = getDisplay(labels[0], l);
                  return name ? (
                    <div className="label" key={"product-list-item-label" + i}>
                      {name}
                    </div>
                  ) : null;
                })}
              </Widget.Row>
              <Widget.Row justify="flex-end">
                <button>BUY</button>
              </Widget.Row>
            </Widget.Row>
          </div>
        </DetailContainer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  padding: 25px;
  background: white;
  width: 100%;
  margin-bottom: 10px;
  ${props => props.css}
`;

const ImageContainer = styled.div`
  position: relative;
  max-width: 215px;
  width: 100%;
  max-height: 215px;

  & > .image {
    background-image: url(${props => props.img});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }

  & > .badge {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #e0a597;
    width: 58px;
    height: 58px;
    font-weight: 200;
    font-size: 12px;
    color: white;

    @media screen and (max-width: 414px) {
      display: none;
    }
  }

  @media screen and (max-width: 414px) {
    max-width: 180px;
    width: 100%;
    max-height: 180px;
  }

  ${props => props.extraCss}
`;

const DetailContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    & h1 {
        font-weight: 400px;
        font-size: 14px;
        color: #4e4e4e;
    }

    & p {
        max-width: 450px;
        white-space: pre-wrap;
        font-size: 14px;
        color: #4e4e4e;

        @media screen and (max-width: 414px){
            display: none;
        }
    }

    & .original-price {
        text-decoration: line-through;
        font-size: 14px;
        color: #BFBFBF;
        ${props => props.css}
    }

    & .price {
        font-weight: 400;
        font-size: 18px;
        color: #4E4E4E;
    }

    & .label {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 14.5px;
        padding: 3px 10px;
        font-weight: 200;
        font-size: 14px;
        color: #8B8B8B;
        border: 1px solid #8B8B8B;
        margin-right: 5px;
    }

    & button {
        border-radius: 14.5px;
        padding: 5px 35px;
        background: #8B8B8B;
        font-size: 14px;
        color: white;
        }
    }
`;

export default ProductListItem;
